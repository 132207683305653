// Generated by Framer (b2780b5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["fQqvtO2gD", "qNXA8RKyr"];

const serializationHash = "framer-ZUbDM"

const variantClassNames = {fQqvtO2gD: "framer-v-11aw1ea", qNXA8RKyr: "framer-v-13mjwa5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "fQqvtO2gD", "Variant 2": "qNXA8RKyr"}

const getProps = ({click, height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "fQqvtO2gD", W41POyFF1: click ?? props.W41POyFF1} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, W41POyFF1, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "fQqvtO2gD", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapnob6k6 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (W41POyFF1) {const res = await W41POyFF1(...args);
if (res === false) return false;}
setVariant("qNXA8RKyr")
})

const onTap1276616 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (W41POyFF1) {const res = await W41POyFF1(...args);
if (res === false) return false;}
setVariant("fQqvtO2gD")
})

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "qNXA8RKyr") return false
return true
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-11aw1ea", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"fQqvtO2gD"} onTap={onTapnob6k6} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({qNXA8RKyr: {"data-framer-name": "Variant 2", onTap: onTap1276616}}, baseVariant, gestureVariant)}><motion.div className={"framer-hle8pv"} layoutDependency={layoutDependency} layoutId={"vUuYOrGlZ"} style={{backgroundColor: "rgb(255, 255, 255)", rotate: 0}} variants={{qNXA8RKyr: {rotate: 45}}}/>{isDisplayed() && (<motion.div className={"framer-1dsuei8"} layoutDependency={layoutDependency} layoutId={"nKc3HDSHk"} style={{backgroundColor: "rgb(255, 255, 255)"}}/>)}<motion.div className={"framer-1px8673"} layoutDependency={layoutDependency} layoutId={"CLzAM5P1A"} style={{backgroundColor: "rgb(255, 255, 255)", rotate: 0}} variants={{qNXA8RKyr: {rotate: -45}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ZUbDM.framer-es7tzm, .framer-ZUbDM .framer-es7tzm { display: block; }", ".framer-ZUbDM.framer-11aw1ea { cursor: pointer; height: 31px; overflow: hidden; position: relative; width: 31px; }", ".framer-ZUbDM .framer-hle8pv { flex: none; height: 2px; left: calc(51.61290322580647% - 27px / 2); overflow: visible; position: absolute; top: 6px; width: 27px; }", ".framer-ZUbDM .framer-1dsuei8 { flex: none; height: 2px; left: calc(51.61290322580647% - 27px / 2); overflow: visible; position: absolute; top: calc(51.61290322580647% - 2px / 2); width: 27px; }", ".framer-ZUbDM .framer-1px8673 { bottom: 5px; flex: none; height: 2px; left: calc(51.61290322580647% - 27px / 2); overflow: visible; position: absolute; width: 27px; }", ".framer-ZUbDM.framer-v-13mjwa5.framer-11aw1ea { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 31px); }", ".framer-ZUbDM.framer-v-13mjwa5 .framer-hle8pv { top: calc(51.61290322580647% - 2px / 2); }", ".framer-ZUbDM.framer-v-13mjwa5 .framer-1px8673 { bottom: unset; top: calc(51.61290322580647% - 2px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 31
 * @framerIntrinsicWidth 31
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"qNXA8RKyr":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"W41POyFF1":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerZEjKCk82F: React.ComponentType<Props> = withCSS(Component, css, "framer-ZUbDM") as typeof Component;
export default FramerZEjKCk82F;

FramerZEjKCk82F.displayName = "hamburger 2";

FramerZEjKCk82F.defaultProps = {height: 31, width: 31};

addPropertyControls(FramerZEjKCk82F, {variant: {options: ["fQqvtO2gD", "qNXA8RKyr"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, W41POyFF1: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerZEjKCk82F, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})